import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "../global/store";

const Standings = (props) => {
  const theme = useTheme();
  const test = props.data;
  const colors = tokens(theme.palette.mode);

  const test_data = useSelector((state) => state.data);

  const columns = [
    { field: "rank", headerName: "Pos.", width: 11 },
    { field: "name", headerName: "Name" },
    { field: "points", headerName: "Points" },
    { field: "diff", headerName: "Difference" },
  ];

  const rows = test_data ? test_data[0] : [];

  return (
    <Provider store={store}>
      <Box m="20px">
        <Header subtitle="Overall"></Header>
        <Box
          m="40px 0 0 0"
          height="25vh"
          margin={-1.8}
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { border: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            density="compact"
            rows={rows}
            columns={columns}
            hideFooter={true}
          ></DataGrid>
        </Box>
      </Box>
    </Provider>
  );
};

export default Standings;
