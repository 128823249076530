import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "../global/store";

const Captains = () => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const golf_data = useSelector((state) => state.data);

  const participants = golf_data[8];

  const columns = [
    { field: "position", headerName: "Pos.", width: 11 },
    { field: "name", headerName: "Player name" },
    {
      field: "score",
      headerName: "Total score",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "scoretoday",
      headerName: "Score today",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "onhole",
      headerName: "On Hole",
      headerAlign: "center",
      align: "center",
    },
    ...participants.map((participant) => ({
      field: participant,
      headerName: participant,
      headerAlign: "center",
      align: "center",
    })),
  ];

  const rows = golf_data ? golf_data[1] : [];
  const tournament_name = `Captains for: ${golf_data[3].name}`;

  return (
    <Provider store={store}>
      <Box m="20px">
        <Header subtitle="Captains"></Header>
        <Box
          m="40px 0 0 0"
          height="22vh"
          margin={-1.8}
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { border: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            density="compact"
            rows={rows}
            columns={columns}
            hideFooter={true}
          ></DataGrid>
        </Box>
      </Box>
    </Provider>
  );
};

export default Captains;
